import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.url
const task = `${HOST}task`

export default {
  getRequests(context1) {
    const context = context1
    const params = {
      params: {
        query: context.$store.state.query.query, page: context.$store.state.query.page - 1, type: context.type,
      },
    }
    axios.get(`${task}/requests`, params).then(response => {
      context.setRequests(response.data)
    }, () => {
    })
  },
  getparentTasks(context1, parentId, parentTypeId) {
    const context = context1
    const params = {
      params: {
        query: context.$store.state.query.query, page: context.$store.state.query.page - 1, type: context.type,
      },
    }
    axios.get(`${task}/${parentTypeId}/${parentId}`, params).then(response => {
      context.setParentTask(response.data)
    }, () => {
    })
  },
  getTaskById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${task}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setTask(response.data)
    }, error => {
      context.$vs.loading.close()
      context.taskNotFound(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${task}/${id}`).then(response => {
      context.$vs.loading.close()
      context.taskDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.taskNotDeleted(error.response.data.return_message)
    })
  },
  addRequest(context1) {
    const context = context1
    context.$vs.loading()
    axios.post(`${task}/request`, context.addRequest).then(response => {
      context.$vs.loading.close()
      context.requestAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.requestNotAdded(error.response.data.return_message)
    })
  },
  updateTaskById(context1, id) {
    const context = context1
    const request = { comments: [] }
    request.comments.push(context.addRequest)
    context.$vs.loading()
    axios.put(`${task}/request/${id}`, request).then(response => {
      context.$vs.loading.close()
      context.requestUpdated(response.data.return_message)
    }, error => {
      context.$vs.loading.close()
      context.requestNotUpdated(error.response.data.return_message)
    })
  },

}

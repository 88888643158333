<template>

  <div>
    <b-card
      class="mb-0"
    >
      <div class="mb-2">
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <h4 class="card-title mb-0 pt-50">
              My Service Requests</h4>
          </b-col>
          <b-col
            v-if="employers.length > 1"
            cols="12"
            md="4"
          >
            <b-form-group
              label="Parent Type"
              label-cols-md="3"
              class="text-right mb-0"
            >
              <v-select
                v-model="parent"
                :options="employers"
                :reduce="item => item"
                label="parent_type"
                :clearable="false"
                placeholder="Select"
                @input="onTypeChanged()"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4 ml-auto"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                :to="{ name: 'requests-new'}"
              >
                <span class="text-nowrap">Add Request</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <vue-good-table
        :columns="columns"
        :rows="requestsResponse.tasks"
        :rtl="direction"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Status -->
          <span v-if="props.column.field === 'cellRendererStatus'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>

          <!-- Column: Comments -->
          <span v-if="props.column.field === 'cellRendererComment'">
            <p
              v-if="props.row.latest_comment !== null"
              class="mb-0"
            >
              <span class="d-inline-block text-truncate read-more mb-0">{{ props.row.latest_comment }}</span>
              <router-link
                :to="{ name: 'requests-view', params: { id: props.row.id}}"
                class="d-inline-block red more-text align-top ml-1"
              > more</router-link>
            </p>
            <p
              v-if="props.row.latest_comment === null"
              class="mb-0"
            >No Comments Available</p>
          </span>

          <!-- Column: Action -->
          <span
            v-else-if="props.column.field === 'action'"
            class="btn-action"
          >
            <span>
              <b-link
                id="view"
                class="ml-1"
                :to="{ name: 'requests-view', params: { id: props.row.id } }"
              >
                <b-badge
                  pill
                  variant="light-primary"
                ><feather-icon icon="EyeIcon" />
                </b-badge>
              </b-link>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
        >
          <div class="d-flex justify-content-end flex-wrap">
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="requestsResponse.count"
                first-number
                last-number
                align="right"
                :per-page="1"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BBadge, BLink, BPagination, BFormGroup,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import store from '@/store/index'
import User from '@/js/user'
import Requests from '@/js/requests'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BLink,
    BPagination,
    BFormGroup,
    VueGoodTable,

    vSelect,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      type: null,
      parent_id: null,
      parent: null,
      employers: [],
      requestsResponse: {
        tasks: [],
        count: 1,
      },
      columns: [
        {
          label: '#',
          field: 'index',
          width: '100px',
        },
        {
          label: 'Name',
          field: 'name',
          width: '240px',
          sortable: false,
        },
        {
          label: 'Latest Comment',
          field: 'cellRendererComment',
          sortable: false,
        },
        {
          label: 'Submitted On',
          field: 'created_date',
          width: '160px',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'cellRendererStatus',
          width: '150px',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          width: '120px',
          sortable: false,
        },
      ],
      rows: [],
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        Requests.getRequests(this)
      },
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Completed     : 'light-success',
        Approved      : 'light-success',
        Submitted     : 'light-primary',
        Rejected      : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.requestsResponse.count = this.$store.state.query.page
    Requests.getRequests(this)
    if (User.profile() != null) {
      this.isJobExist = User.profile().timesheet_parent != null || User.profile().request_parents.length > 0 || User.profile().request_parents.length > 0
    }
    this.employers = User.profile().request_parents
  },
  methods: {
    onTypeChanged() {
      this.type = this.parent.parent_type_id
      Requests.getRequests(this)
    },
    updateSearchQuery() {
      this.$store.state.query.query = this.searchQuery
      this.currentPage = 1
    },
    setRequests(requestsResponse) {
      this.requestsResponse = requestsResponse
    },
  },
}
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
